var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Инвест-дома")]),_c('invest-companies-filters',{attrs:{"table-params":_vm.tableParams},on:{"change-field":_vm.setTableParams}}),_c('data-table',{ref:"dataTable",attrs:{"url":"investment-companies","headers":_vm.headers,"table-params":_vm.tableParams},scopedSlots:_vm._u([{key:"item.forRuStocks",fn:function(ref){
var investCompany = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesNo")(investCompany.forRuStocks))+" ")]}},{key:"item.expertsCount",fn:function(ref){
var investCompany = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(investCompany.expertsCount,{ fractionDigits: 0 }))+" ")]}},{key:"item.synonymsCount",fn:function(ref){
var investCompany = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(investCompany.synonymsCount,{ fractionDigits: 0 }))+" ")]}},{key:"item.actions",fn:function(ref){
var investCompany = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.editInvestCompany(investCompany)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)}),_c('v-divider',{staticClass:"my-6"}),_c('div',{staticClass:"text-h6 mb-3"},[_vm._v("Новый инвест-дом")]),_c('create-invest-companies-form')],1)}
var staticRenderFns = []

export { render, staticRenderFns }